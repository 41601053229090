package senses.api.interfaces;

import tink.core.Promise;
import haxe.io.Bytes;
import tink.http.Response;
import tink.http.Response.ResponseHeader;

interface IParts {
    @:restrict(true)
    @:get('/get/$emailID/$partID')
    function getPart(emailID:String, partID:String):Promise<IPart>;

    @:restrict(true)
    @:get('/getAttachment/$emailID/$partID')
    @:produces('application/octet-stream')
    function getAttachment(emailID:String, partID:String):Promise<Bytes>;

    @:restrict(true)
    @:get('/getAttachmentLink/$emailID/$partID')
    @:produces('application/json')
    function getAttachmentLink(emailID:String, partID:String):Promise<IPart>;

    //@:restrict(true)
    @:get('/getAttachmentFromLink/$linkID')
    @:produces('application/octet-stream')
    function getAttachmentFromLink(linkID:String):Promise<OutgoingResponse>;

    @:restrict(true)
    @:get('/getEMLLink/$emailID')
    @:produces('application/json')
    function getEMLLink(emailID:String):Promise<IPart>;

    //@:restrict(true)
    @:get('/getEMLFromLink/$linkID')
    @:produces('application/octet-stream')
    function getEMLFromLink(linkID:String):Promise<OutgoingResponse>;
}

typedef IPart = {
    content: String
};
