package senses.mails;

import js.Browser;
import haxe.io.Error;
import coconut.data.Model;
import senses.mails.atoms.CustomRaw;
import js.html.DOMParser;
import global.XssGlobal;
import senses.models.mails.MailPart;
import senses.models.mails.Attachment;
import coconut.ui.View;
import coconut.ui.View;
import tink.core.Option;

class PartTypeView extends View {
    @:attribute var partType:MailPartType;
    @:attribute var emailID:String;

    function render() {
        switch (partType) {
            case TextHtml(content):
                return hxx('<MailHTMLView part=${partType} />');
            case TextPlain(content):
                return hxx('<CustomRaw content=${StringTools.replace(StringTools.htmlEscape(content), "\n", "<br/>")} />');
            case MultipartAlternative(parts):
                return hxx('<MultipartAlternativeView emailID=${emailID} partType=${partType}/>');
            case MultipartMixed(parts):
                return hxx('
                    <div>
                        <for ${part in parts}>
                            <switch ${part.part}>
                                <case ${Done(partType)}>
                                    <PartTypeView emailID=${emailID} partType=${partType} />
                                <case ${_} >
                            </switch>
                        </for>
                    </div>
                ');
            case MultipartRelated(parts):
                return hxx('
                    <div>
                        <for ${part in parts}>
                            <switch ${part.part}>
                                <case ${Done(partType)}>
                                    <PartTypeView emailID=${emailID} partType=${partType} />
                                <case ${_} >
                            </switch>
                        </for>
                    </div>
                ');
            case Unknown(part):
                switch (part.headers) {
                    case Done(headers):    
                        var contentDisposition = headers.get('content-disposition');
                        switch (contentDisposition.first()) {
                            case Some(v):
                                v = StringTools.replace(v, "\t", " ");
                                var sh = v.split(';');
                                var cd = tools.ContentType.parse(v);
                                var disposition = cd.type;
                                var filename:String = cd.parameters.filename;
                                switch (disposition) {
                                    case 'attachment':
                                        return hxx('<AttachmentView emailID=${emailID} attachment=${new Attachment({filename: filename, contentType:""})}/>');
                                    default:
                                }
                            case None:
                                return hxx('<div>Unknown part type (${extractSome(headers.get('content-type').first())})</div>');

                        }
                    case Failed(e):
                    case Loading:
                }
            default:
                return hxx('<div>Default</div>');
        }
        return hxx('<div>Unknown part type</div>');
    }

    inline function extractSome(value:Option<Dynamic>) {
        switch (value) {
            case Some(v):
                return v;
            case None:
                return "nope";
        }
    }
}

class AttachmentView extends View {
    @:attribute var attachment:Attachment;
    @:attribute var emailID:String;

    function handleClick() {
        Main.remote.parts().getAttachmentLink(emailID, attachment.filename).handle(
            (outcome) -> {
                switch (outcome) {
                    case Success(data):
                        var link = data.content;
                        Browser.window.open('https://api.latearrivalstud.io/parts/getAttachmentFromLink/${link}');
                    case Failure(failure):
                        throw failure;
                }
            }
        );
    }

    function render() {
        return 
            <div onclick=${handleClick()}>
                Attachment: ${attachment.filename} (${attachment.contentType})
            </div>
        ;
    }
}
