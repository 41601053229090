package senses.models;

import tink.core.Option;
import senses.atoms.ToastModel;
import haxe.Json;
import senses.api.interfaces.IRoot;
import tink.web.proxy.Remote;
import coconut.data.Model;
import tink.pure.List;
import senses.models.summaries.Summary;
import senses.models.SessionManagement;

class AppModel implements Model {
    @:editable var summaries:List<Summary> = new List();

    @:editable var currentMail:Null<Summary> = null;
    @:editable public var remote:Remote<IRoot> = null;

    @:editable var showWriteMailPopover:Bool = false;
    @:editable var writeMailPopupModels:List<WriteMailPopupModel> = new List();

    @:observable var toastModels:List<ToastModel> = new List();
    @:computed var currentToast:Option<ToastModel> = toastModels.first();

    @:editable var summariesLoading:LoadingState = Never;

    public var sessionManagement:SessionManagement;

    @:transition function addToast(toast:ToastModel) {
        return {
            toastModels: toastModels.append(toast)
        };
    }

    @:transition function removeToast(toast:ToastModel) {
        return {
            toastModels: toastModels.filter((ctoast) -> {
                return ctoast == toast ?
                    FilterResult.Exclude
                    : FilterResult.Include;
            })
        };
    }

    public function loadSummaries() {
        summariesLoading = Loading;
        remote.summaries().getAll().handle((outcome) -> {
            switch (outcome) {
                case Success(data):
                    summariesLoading = Done;
                    var mappedData = data.map((sum) -> new Summary({id: sum.id, subject: sum.subject, read: sum.read, from: List.fromArray(Json.parse(sum.from)), hidden: sum.hidden}));
                    mappedData = mappedData.filter((sum) -> !sum.hidden);
                    mappedData.reverse();
                    summaries = tink.pure.List.fromArray(mappedData);
                    trace('Success', data);
                case Failure(error):
                    summariesLoading = Error;
                    trace('Error', error);
            }
        });
    }
}

class WriteMailPopupModel implements Model {
    @:editable var body:String;
    @:editable var to:String;
    @:editable var from:String;
    @:editable var subject:String;
}

enum LoadingState {
    Never;
    Loading;
    Done;
    Error;
}