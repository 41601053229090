package senses.atoms;

import js.html.TextAreaElement;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('TextInput.css'))
class TextArea extends View {
    @:controlled var value:String;
    @:attribute var isPassword:Bool = false;
    @:attribute var placeholder:String = "";
    @:attribute var className:String = "";
    @:ref var inputElement:TextAreaElement;

    function handleChange(e) {
        value = inputElement.value;
    }

    function render() {
        return
            <textarea placeholder=${placeholder} class=${'${TextInput.__pssClassName} ${className}'} ref=${inputElement} value=${value} onchange=${(e) -> handleChange(e)}>
            </textarea>
        ;
    }
}