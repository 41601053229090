import senses.atoms.Toast;
import senses.atoms.MinimalistButton;
import senses.atoms.Toolbar;
import senses.forms.WriteMailPopover;
import senses.MaterialIcon;
import senses.atoms.Button;
import senses.LoginScreen;
import senses.mails.MailView;
import senses.summaries.SummariesList;
import coconut.ui.View;

import tink.pure.List;
import senses.models.summaries.Summary;

import senses.models.mails.MailPart;
import senses.models.SessionManagement;
import senses.models.AppModel;

@:build(papillotte.macros.PSSBuilder.build('App.css'))
class App extends View {
    @:attribute var model:AppModel;
    @:attribute var sessionManagement:SessionManagement;

    @:state var mailModel:MailPart = new MailPart({id:null, emailID: null});
    @:state var showWriteModal:Bool = false;

    function handleMailActivated(summary:Summary) {
        trace('Model should change to summary ${summary.subject}');
        this.mailModel = new MailPart({emailID: summary.id, id: summary.id});
        model.remote.summaries().setRead(true, summary.id).handle((outcome) -> {
            switch (outcome) {
                case Success(data):
                    trace('Success:', data);
                    summary.read = data.success;
                case Failure(failure):
                    trace('Failure');
            }
        });
    }
    function render() {
            return hxx('
            <div className=${App.__pssClassName}>
                <if ${sessionManagement.sessionID != null}>
                    <div style=${{height: "100%"}}>
                        <Toolbar>
                            <Button onclick=${(_)-> {
                                var mailModel = new WriteMailPopupModel({
                                    body: "",
                                    to: "",
                                    from: "",
                                    subject: "Default subject",
                                });
                                model.writeMailPopupModels = model.writeMailPopupModels.append(mailModel);
                            }}>Write</Button>
                            <MinimalistButton onclick=${model.loadSummaries()}>Refresh</MinimalistButton>
                            <switch ${model.summariesLoading}>
                                <case ${Never}>
                                    ❓
                                <case ${Loading}>
                                    💬
                                <case ${Done}>
                                    ✅
                                <case ${Error}>
                                    ❌
                            </switch>
                        </Toolbar>
                        <div class=${'${App.__pssClassName}-mainContent'}>
                            <SummariesList onMailActivated=${handleMailActivated} summaries=${model.summaries}/>
                            <MailView mailModel=${this.mailModel}/>
                        </div>
                    </div>
                    <for ${mailPopup in model.writeMailPopupModels}>
                        <WriteMailPopover viewModel=${mailPopup} appModel=${model} to=${mailPopup.to} message=${mailPopup.body} subject=${mailPopup.subject} />
                    </for>
                <else>
                    <LoginScreen 
                        sessionManagement=${sessionManagement}
                        appModel=${model}
                    />
                </if>
                <switch ${model.currentToast}>
                    <case ${Some(toastModel)}>
                        <Toast toast=${toastModel} appModel=${model}></Toast>
                    <case ${None}>
                </switch>
            </div>
            ');
    }
}