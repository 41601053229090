package senses.atoms;

import coconut.ui.Children;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('Popover.css'))
class Popover extends View {
    @:children var content:Children;
    @:attribute var cancelable:Bool = false;
    @:attribute var showBackground:Bool = true;
    @:attribute var position:PopoverPosition = Center;
    @:attribute var actions:Children="";
    @:attribute var title:String="";
    @:controlled var open:Bool;

    @:ref var backgroundElement:js.html.Element;

    function handleBackgroundClick(e:js.html.MouseEvent) {
        if (e.target != backgroundElement || !cancelable) {
            return;
        }

        open = false;
    }

    function createClassObject() {
        var object = {};
        Reflect.setField(object, Popover.__pssClassName, true);
        Reflect.setField(object, '${Popover.__pssClassName}-hide-background', !showBackground);
        
        return object;
    }

    function createContentClassObject() {
        var object = {};
        Reflect.setField(object, '${Popover.__pssClassName}-content', true);

        switch (position) {
            case Center:
                Reflect.setField(object, '${Popover.__pssClassName}-content-center', true);
            case ReferencePoint(x, y):
                //Reflect.setField(object, '${Popover.__pssClassName}-center', true);
        }

        return object;
    }

    function createContentStyleObject():tink.domspec.Style {
        switch (position) {
            case Center:
                return {};
            case ReferencePoint(x, y):
                return {left: '${x}px', top:'${y}px'};
        }
    }

    function handleClose(_) {
        open = false;
    }

    function render() {
        return
        <if ${open}>
            <div ref=${backgroundElement} class=${createClassObject()} onclick=${(e) -> handleBackgroundClick(e)}>
                <div class=${createContentClassObject()} style=${createContentStyleObject()}>
                    <div class='${Popover.__pssClassName}-close' onclick=${handleClose}><MaterialIcon name="close"/></div>
                    <div class='${Popover.__pssClassName}-title'>${title}</div>
                    <div class='${Popover.__pssClassName}-inner'>
                        ${...content}
                    </div>
                    <div style={{float: "right"}}>
                        ${...actions}
                    </div>
                </div>
            </div>
        </if>
        ;
    }
}

enum PopoverPosition {
    Center;
    ReferencePoint(x:Int, y:Int);
}