package senses.api.interfaces;

import tink.core.Promise;

interface ISummaries {
    @:restrict(true)
    @:get('/all')
    function getAll():Promise<Array<IMailSummary>>;

    @:restrict(true)
    @:post('/$id/read')
    @:consumes("application/json")
    function setRead(body:Bool, id:String):Promise<ISimpleAnswer>;

    @:restrict(true)
    @:post('/$id/hidden')
    @:consumes("application/json")
    function setHidden(body:Bool, id:String):Promise<ISimpleAnswer>;
}

typedef IMailSummary = {
    id:String,
    subject:String,
    read:Bool,
    from:String,
    hidden: Bool,
};

typedef ISimpleAnswer = {
    success:Bool
};