package senses;

import senses.atoms.ToastModel;
import senses.forms.CreateUserForm;
import senses.atoms.Popover;
import senses.atoms.Button;
import senses.atoms.TextInput;
import js.html.InputElement;
import senses.api.interfaces.IRoot;
import tink.web.proxy.Remote;
import senses.models.SessionManagement;
import Main.MyClient;
import coconut.ui.View;
import tink.url.Host;
import senses.models.summaries.Summary;
import senses.models.AppModel;


@:build(papillotte.macros.PSSBuilder.build('LoginScreen.css'))
class LoginScreen extends View {
    @:attribute var sessionManagement:SessionManagement;
    @:attribute var appModel:AppModel;

	@:ref var backendInput:InputElement;

    @:state var name:String = "";
    @:state var password:String = "";
    @:state var backend:String = "";

    @:controlled var open:Bool = true;

    function handleValidation() {
        trace('Validation');
        appModel.remote = new Remote<IRoot>(new MyClient(sessionManagement), new RemoteEndpoint(new Host(/*backendInput.value*/ backend, 443),null, 'https'));
        Main.remote = appModel.remote;

		appModel.remote.users().login({name: name, password: password}).handle(outcome -> {
            trace(name, password);
			switch (outcome) {
				case Failure(error):
					trace(error);
                    appModel.addToast(new ToastModel({text: "Failed to contact server"}));
				case Success(data):
                    if (data.sessionID == null) {
                        appModel.addToast(new ToastModel({text: "Failed to log in"}));
                        return;
                    }
					sessionManagement.sessionID = data.sessionID;
                    trace('Set sessionID to ${sessionManagement.sessionID}');

					appModel.loadSummaries();
                    appModel.addToast(new ToastModel({text: "Logged in"}));
			}
		});
    }

    function render() {
        return 
            <Popover 
                open=${open} 
                position=${Center} 
                actions={<Button onclick=${handleValidation()}>Login</Button>}
                title="Login"
                >
                    <TextInput value=${name} placeholder="Username" className='${LoginScreen.__pssClassName}-fields' /><br/>
                    <TextInput isPassword=${true} value=${password} className='${LoginScreen.__pssClassName}-fields' placeholder="Password" /><br/>
                    <TextInput value=${backend} placeholder="Backend" className='${LoginScreen.__pssClassName}-fields' /><br/>
            </Popover>
        ;
    }

}