package senses.atoms;

import coconut.ui.Children;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('MinimalistButton.css'))
class MinimalistButton extends View {
    @:attribute var children:Children;
    @:attribute var onclick:MinimalistButton->Void=null;

    function handleOnClick() {
        if (Reflect.isFunction(onclick)) {
            this.onclick(this);
        }
    }

    function render() {
        return
            <button onclick=${handleOnClick()} className=${MinimalistButton.__pssClassName}>${...children}</button>
        ;
    }
}