package senses.models.summaries;

import tink.pure.List;
import coconut.data.Model;

class Summary implements Model {
    @:constant var subject:String;
    @:constant var id:String;
    @:editable var read:Bool;
    @:constant var from:List<String>;
    @:constant var hidden:Bool;
}