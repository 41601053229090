
#if macro
package papillotte.macros;

import sys.io.FileOutput;
import sys.FileSystem;
import sys.io.File;
import haxe.macro.Expr.FieldType;
import haxe.macro.Expr.Access;
import haxe.macro.Context;

class PSSBuilder {
    static inline var CURRENT_CLASS = "CURRENT_CLASS";

    static var firstRun:Bool = true;

	static inline var PSSOUTPUT = 'PSSOutput';

	static inline var PSSBUILDER_MACRO = "PSSBuilder Macro";

	static inline var NO_PSSOUTPUT_MSG = 'PSSOutput is not defined. Use -D ${PSSOUTPUT}= to specify output file.';

	static inline var PSSCLASSNAME = "__pssClassName";

	static public function build(fileName:String) {
        var classType = Context.getLocalClass().get();
        var fullClassName = classType.pack.join('-') + '-' + classType.name;
        var filePath = getFilePath(fileName);
        //TODO: Handle exception if file doesn't exist.
        var pss = File.getContent(filePath);
        pss = StringTools.replace(pss, CURRENT_CLASS, fullClassName);
        var outputFile = Context.definedValue(PSSOUTPUT);
        if (outputFile == null) {
            Context.fatalError(NO_PSSOUTPUT_MSG, Context.makePosition({min:0, max:0, file: PSSBUILDER_MACRO}));
        }
        var out:FileOutput;
        if (firstRun) {
            out = File.write(outputFile);
            firstRun = false;
            
        }
        else {
            out = File.append(outputFile);
        }    
        out.writeString('\n$pss');
        out.close();

		var fields = Context.getBuildFields();
		var newField = {
			name: PSSCLASSNAME,
			access: [Access.APublic, Access.AStatic, Access.AInline, Access.AFinal],
			kind: FieldType.FVar(macro:String, macro $v{fullClassName}),
			pos: Context.currentPos(),
		};
		fields.push(newField);
		return fields;
    }

    static function getFilePath(fileName:String):String {
        var currentFile = Context.getPosInfos(Context.currentPos()).file;
        var pathElements = currentFile.split('/');
        pathElements.pop();
        pathElements.push(fileName);
        return pathElements.join('/');
    }
}
#end
