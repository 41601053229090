package senses.mails;

import tink.core.Error;
import tink.pure.List;
import senses.models.mails.MailPart;
import coconut.ui.View;

class MultipartAlternativeView extends View {
    @:attribute var partType:MailPartType;
    @:attribute var emailID:String;

    function render() {
        if (partType == null) return '';
        switch (partType) {
            case MultipartAlternative(parts):
                var parts = orderPartsByPreference(parts);
                if (parts[0] != null) {
                    return hxx('
                    <div>
                    <switch ${parts[0].part}>
                        <case ${Done(v)}>
                            <PartTypeView emailID=${emailID} partType=${v} />
                        <case ${Failed(e)}>
                            <div>Error</div>
                        <case ${Loading}>
                            <div>Loading</div>
                        <case ${_}>
                            <span>plop</span>
                    </switch>
                    </div>
                    ');
                } else {
                    return null;
                }
            default:
                throw new Error('partType should be MultipartAlternative in MultipartAlternativeView');
        }
    }

    function orderPartsByPreference(parts:List<MailPart>) {
        var arr = Lambda.array(parts);
        arr.sort((one, two) -> {
            return getPartPriority(one) - getPartPriority(two);
        });
        return arr;
    }

    function getPartPriority(part:MailPart) {
        switch (part.part) {
            case Done(v):
                switch (v.getName().toLowerCase()) {
                    case "texthtml":
                        return 0;
                    case "textplain":
                        return 1;
                    default:
                        return 9998;
                }
            case Failed(e):
                return 9999;
            case Loading:
                return 9999;
        }
    }
}