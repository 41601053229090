package senses.api.interfaces;

interface IRoot {
    @:get('/')
    @:get('/$name')
    function hello(?name:String):String;

    @:sub('/summaries')
    public function summaries():ISummaries;

    @:sub('/parts')
    public function parts():IParts;

    @:sub('/users')
    public function users():IUsers;

    @:sub('/sending')
    public function sending():ISending;
}