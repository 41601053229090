import js.html.Blob;
import coconut.data.Model;
import tink.http.Header.HeaderField;
import tink.http.Response.IncomingResponse;
import tink.core.Promise;
import tink.http.Request.OutgoingRequest;
import senses.models.summaries.Summary;
import js.Browser;
import coconut.ui.Renderer;
import tink.http.clients.*;
import tink.web.proxy.Remote;
import tink.url.Host;
import senses.api.interfaces.IRoot;
import senses.models.AppModel;
import senses.models.SessionManagement;
import senses.models.mails.MailPart;

class Main {
	//FIXME: This should be removed!
	public static var appModel:AppModel;
	//FIXME: This should be removed!
	public static var remote:Remote<IRoot>;

	public static function main() {
        var sessionManagement = new SessionManagement();
		var appModel = new AppModel({sessionManagement: sessionManagement});
		Main.appModel = appModel;

		Renderer.mount(Browser.document.getElementById('app'), "<App sessionManagement=${sessionManagement} model=${appModel}/>");
	}
}

class MyClient extends JsClient {
    public var sessionManagement:SessionManagement;

	override public function request(req:OutgoingRequest):Promise<IncomingResponse> {
		if (sessionManagement.sessionID != null) {
			var headers = req.header.concat([new HeaderField("X-Auth-Session", sessionManagement.sessionID)]);
			var headers = headers.concat([new HeaderField("Access-Control-Allow-Origin", "*")]);
			return super.request(new OutgoingRequest(headers, req.body));
		}

		return super.request(req);
	}

	public function new(sessionManagement:SessionManagement) {
		super();

        this.sessionManagement = sessionManagement;
	}

	/*function downloadAttachment(email:String, part:String) {
		var http = new haxe.Http('');
		http.setHeader("X-Auth-Session", sessionManagement.sessionID);
		http.setHeader("Access-Control-Allow-Origin", "*");

		http.onBytes = (b)-> {
			new Blob([b.toHex()]);	
		};
	}*/
}

