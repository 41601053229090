package senses.mails.atoms;

import coconut.ui.View;
import tink.pure.Dict;
import tink.pure.List;
import tink.state.Promised;

@:build(papillotte.macros.PSSBuilder.build('HeaderView.css'))
class HeaderView extends View {
    @:attribute var headers:Promised<Dict<String, List<String>>>;
    @:attribute var targetHeader:String;
    @:attribute var targetIndex:Int;

    function render() {
        return hxx('
        <switch ${headers}>
            <case ${Loading}>
                Loading...
            <case ${Failed(err)}>
                Oops
            <case ${Done(v)}>
                <let value=${v.get(targetHeader).get(targetIndex)}>
                    <switch ${value}>
                        <case ${Some(v)}>
                            ${v}
                        <case ${None}>
                            (none)
                    </switch>
                </let>
        </switch>
        ');
    }
}