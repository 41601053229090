package senses.atoms;

import senses.models.AppModel;
import tink.pure.List.FilterResult;
import haxe.Timer;
import coconut.ui.Children;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('Toast.css'))
class Toast extends View { 
    @:attribute var toast:ToastModel;
    @:attribute var appModel:AppModel;

    function viewDidMount() {
        var disappearTimer = Timer.delay(() -> {
            close();
        }, 2000);
        beforeUnmounting(() -> {
            disappearTimer.stop();
            disappearTimer = null;
        });
    }

    function close() {
        appModel.removeToast(toast);
    }

    function render() {
        return
            <div className=${Toast.__pssClassName}>${toast.text}</div>
        ;
    }
}