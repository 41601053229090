package senses.atoms;

import coconut.ui.Children;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('Button.css'))
class Button extends View {
    @:attribute var children:Children;
    @:attribute var onclick:Button->Void=null;

    function handleOnClick() {
        if (Reflect.isFunction(onclick)) {
            this.onclick(this);
        }
    }

    function render() {
        return
            <button onclick=${handleOnClick()} className=${Button.__pssClassName}>${...children}</button>
        ;
    }
}