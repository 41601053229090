package senses;

import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('MaterialIcon.css'))
class MaterialIcon extends View {
    @:attribute var name:String;
    
    function render() {
        var classes = {};
        Reflect.setField(classes, 'material-icons-outlined', true);
        Reflect.setField(classes, MaterialIcon.__pssClassName, true);

        return 
            <span class=${classes}>
                ${name}
            </span>
        ;
    }
}