package senses.atoms;

import js.html.InputElement;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('TextInput.css'))
class TextInput extends View {
    @:controlled var value:String;
    @:attribute var placeholder:String = "";
    @:attribute var isPassword:Bool = false;
    @:attribute var className:String = "";
    @:ref var inputElement:InputElement;

    function handleChange(e) {
        value = inputElement.value;
    }

    function render() {
        return hxx('
            <input placeholder=${placeholder} class=${'${TextInput.__pssClassName} ${className}'}  ref=${inputElement} type=${isPassword ? "password" : "text"} value=${value} onchange=${(e) -> handleChange(e)} />
        ');
    }
}