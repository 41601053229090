package senses.forms;

import senses.atoms.Button;
import coconut.ui.Children;
import js.html.InputElement;
import senses.atoms.Popover;
import coconut.ui.View;
import senses.atoms.TextInput;

class CreateUserForm extends View {
    @:controlled var open:Bool = true;
    @:ref var nameField:InputElement;
    @:ref var passwordField:InputElement;

    @:state var name:String = "";
    @:state var password:String = "";

    function render() {
        return
            <Popover open=${open}>
                <form>
                    <label>Username<TextInput value=${name} /></label><br/>
                    <label>Password<TextInput isPassword=${true} value=${password} /></label><br/>
                    <Button>Save</Button>
                </form>
            </Popover>
        ;
    }
}