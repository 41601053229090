package senses.api.interfaces;

import tink.core.Promise;

interface ISending {
    @:post('/')
    @:consumes('application/json')
    @:restrict(true)
    public function sendSimple(body:TSendSimpleRequest):Promise<ISendSimpleResponse>;

    @:post
    @:consumes('application/json')
    @:restrict(true)
    public function initFileUpload(body:TInitFileUploadRequest):Promise<TInitFileUploadResponse>;

    @:post
    @:consumes('application/json')
    @:restrict(true)
    public function addFileUploadChunk(body:TAddFileUploadChunkRequest):Promise<TAddFileUploadChunkResponse>;

    @:post
    @:consumes('application/json')
    @:restrict(true)
    public function closeFileUpload(body:TCloseFileUploadRequest):Promise<TCloseFileUploadResponse>;
}

typedef ISendSimpleResponse = {
    success: Bool,
    id: String
};

typedef TSendSimpleRequest = {
    html: String,
    plain: String,
    from: String,
    to: String,
    subject: String,
    ?attachmentsIDs: Array<String>
};

typedef TInitFileUploadRequest = {
    filename:String,
    estimatedSize:Float
};

typedef TInitFileUploadResponse = {
    uploadID:String
};

typedef TAddFileUploadChunkRequest = {
    uploadID:String,
    chunk:String
};

typedef TAddFileUploadChunkResponse = {
    success:Bool
};

typedef TCloseFileUploadRequest = {
    uploadID:String
};

typedef TCloseFileUploadResponse = {
    success:Bool
};