package senses.summaries;

import js.html.MouseEvent;
import coconut.ui.View;
import senses.models.summaries.Summary;

@:build(papillotte.macros.PSSBuilder.build('SummaryListEntry.css'))
class SummaryListEntry extends View {
    @:attribute var summary:Summary;
    @:attribute function onClick(summary:Summary):Void {};

    function parseFromHeader(from:String):{name:String, email:String} {
        var complexHeader = ~/"?(.+[^"])"? <(.+@.+\..+)>/;

        if (complexHeader.match(from)) {
            var name = complexHeader.matched(1);
            var email = complexHeader.matched(2);
            return {name: name, email: email};
        } else {
            return {name: null, email: from}
        }
    }

    function createClassObject() {
        var object = {};
        Reflect.setField(object, SummaryListEntry.__pssClassName, true);
        Reflect.setField(object, '${SummaryListEntry.__pssClassName}-read', summary.read);

        return object;
    }

    function handleRightClick(e:MouseEvent) {
        e.preventDefault();

        trace('Open @ ${e.screenX}, ${e.screenY}');
        return false;
    }

    function getFromString(from:String):String {
        var emailData = parseFromHeader(from);
        if (emailData.name == null) {
            return emailData.email;
        } else {
            return emailData.name;
        }
    }

    public function render() {
        return hxx('
        <li oncontextmenu=${handleRightClick} onclick=${onClick(summary)} class=${createClassObject()}>
            <div className=${'${SummaryListEntry.__pssClassName}-avatar'}>
                <div className=${'${SummaryListEntry.__pssClassName}-avatar-inside'}>
                    <div className=${'${SummaryListEntry.__pssClassName}-avatar-inside-letter'}>
                        ${
                            {
                                var from = parseFromHeader(switch (summary.from.get(0)) {
                                    case None: '';
                                    case Some(v): v;
                                });
                                if (from.name != null) {
                                    from.name.charAt(0);
                                } else {
                                    "?";
                                }
                            }
                        }
                    </div>
                </div>
            </div>
            <div className=${'${SummaryListEntry.__pssClassName}-subject'}>
                ${summary.subject}
            </div>
            <div className=${'${SummaryListEntry.__pssClassName}-from'}>
                ${switch (summary.from.get(0)) {
                    case None: '';
                    case Some(v): getFromString(v);
                }}
            </div>
        </li>'
        );
    }
}