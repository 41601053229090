package senses;

import coconut.ui.RenderResult;
import coconut.ui.View;

@:build(papillotte.macros.PSSBuilder.build('ToggleButton.css'))
class ToggleButton extends View {
    @:child var label:RenderResult;
    @:attribute var title:String = "";
    @:controlled var checked:Bool;

    function handleClick() {
        checked = !checked;
    }

    function render() {
        var classes = {checked: checked};
        Reflect.setField(classes, ToggleButton.__pssClassName, true);
        return 
            <span className=${classes} onclick=${handleClick} title=${title}>
                ${label}
            </span>
        ;
    }
}