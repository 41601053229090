package senses.mails;

import senses.mails.atoms.CustomRaw;
import tink.core.Error;
import senses.models.mails.MailPart.MailPartType;
import senses.models.mails.RawHTMLModel;
import coconut.ui.View;

class MailHTMLView extends View {
    @:attribute var part:MailPartType;
    @:state var displayImages:Bool = false;
    @:state var displayLinks:Bool = false;

    function handleDisplayImagesChange(value:Bool) {
        displayImages = value;
    }

    function handleDisplayLinksChange(value:Bool) {
        displayLinks = value;
    }
    
    function render() {
        switch (part) {
            case TextHtml(content):
               
                

                return 
                    <div>
                        <ToggleButton title="Show images" checked=${displayImages}><MaterialIcon name="image"/></ToggleButton>
                        <ToggleButton title="Activate links" checked=${displayLinks}><MaterialIcon name="link"/></ToggleButton>
                        <RawHTMLView html=${new RawHTMLModel({content: content, displayImages: displayImages, displayLinks: displayLinks})} />
                    </div>
                ;
            default:
                throw new Error('partType should be TextHtml in MailHTMLView');
        }
    }
}

class RawHTMLView extends View {
    @:attribute var html:RawHTMLModel;

    function render() {
        return hxx('<CustomRaw content=${html.filteredContent} />');
    }
}
