package senses.models.mails;

import coconut.data.Model;
import js.html.DOMParser;
import global.XssGlobal;
import global.XssGlobal.CssGlobal;

class RawHTMLModel implements Model {
    @:editable var content:String;
    @:computed var filteredContent:String = filterContent();
    @:editable var displayImages:Bool;
    @:editable var displayLinks:Bool;
    
    function filterContent():String {
        var defaultXSSWhiteList = CssGlobal.getDefaultWhiteList();
        for (field in Reflect.fields(defaultXSSWhiteList)) {
            Reflect.field(defaultXSSWhiteList, field).push('style');
        }
        var defaultCSSWhiteList = CssStylesGlobal.getDefaultWhiteList();

        Reflect.setField(defaultCSSWhiteList, "background-image", displayImages);
        content = new DOMParser().parseFromString(content, TEXT_HTML).body.innerHTML;
        var generatedHTML = XssGlobal.filterXSS(content, {
            whiteList: defaultXSSWhiteList,
            css: {
                whiteList: defaultCSSWhiteList,
                onAttr: function (name, value:String, options) {
                    //trace('css onAttri ${name}, ${value}', ${options});
                    if (value.indexOf('url') > 0) {
                        trace('css onAttri ${name}, ${value}', ${options});
                    }
                },
                onIgnoreAttr: function (name, value:String, options) {
                    //trace('css onIgnore ${name}, ${value}', ${options});
                    if (value.indexOf('url') > 0) {
                        trace('css onAttri ${name}, ${value}', ${options});
                    }
                }
            },
            onTagAttr: (tag, name, value, isWhite) -> {
                if (tag == "a" && name == "href") {
                    if (!displayLinks) {
                        return '';
                    } else {
                        return null;
                    }
                }
                if (tag == "img" && name == "src") {
                    trace('mon displayImages', displayImages);
                    return displayImages ? null : 'galabru';
                }
                if (tag == "html") {
                    trace('PLOP HTML');
                    return "div class='mailHtml'";
                }
                if (name == "style") {
                    trace('YEAH STYLE');
                }
                return null;
            },
            stripIgnoreTagBody: [],
            stripIgnoreTag: true,
            onIgnoreTagAttr: (tag, name, value, isWhite) -> {
                return null;
            }
        });

        return generatedHTML;
    }
}