package senses.forms;

import senses.atoms.ToastModel;
import tink.pure.List.FilterResult;
import haxe.io.Bytes;
import haxe.crypto.Base64;
import haxe.io.UInt8Array;
import js.html.FileReader;
import tink.core.Promise;
import senses.atoms.TextArea;
import senses.forms.CreateUserForm;
import senses.atoms.Popover;
import senses.atoms.Button;
import senses.atoms.TextInput;
import js.html.InputElement;
import senses.api.interfaces.IRoot;
import tink.web.proxy.Remote;
import senses.models.SessionManagement;
import coconut.ui.View;
import tink.url.Host;
import senses.models.summaries.Summary;
import senses.models.AppModel;

@:build(papillotte.macros.PSSBuilder.build('WriteMailPopover.css'))
class WriteMailPopover extends View {
	@:attribute var appModel:AppModel;

	@:attribute var viewModel:WriteMailPopupModel;

	@:controlled var to:String = "";
	@:controlled var subject:String = "";
	@:controlled var message:String = "";

	@:controlled var open:Bool = true;

	@:ref var fileInput:InputElement;

	@:state var uploadID:String = null;

	function handleSend() {
		trace('Validation');
		appModel.remote.sending().sendSimple({
			html: '<!DOCTYPE html>
            <html>
                <head>
                    <title>${subject}</title>
                </head>
                <body>
                    <span>${StringTools.replace(message, '\n', "<br />")}</span>
                </body>
            </html>',
			subject: subject,
			to: to,
			from: 'benjamin.dasnois@latearrivalstud.io',
			plain: message,
			attachmentsIDs: uploadID != null ? [uploadID] : null
		}).handle((outcome) -> {
			switch (outcome) {
				case Success(data):
					trace('success');
					close();
				case Failure(err):
					trace('error');
			}
		});
	}

	function fileChange(input:js.html.InputElement) {
		var file = fileInput.files[0];
		trace('File Change');
		trace(file);

		var reader = new FileReader();
		reader.readAsArrayBuffer(file);

		reader.onload = function() {
			var binary = '';
			// var bytes = new UInt8Array(reader.result);
			var bytes = Bytes.ofData(reader.result);
			var len = bytes.length;

			var toSend = Base64.encode(bytes);
			appModel.remote.sending().initFileUpload({filename: file.name, estimatedSize: 0}).handle((outcome) -> {
				switch (outcome) {
					case Success(data):
						trace('Got ID', data.uploadID);
						this.uploadID = data.uploadID;

						Promise.inSequence([
							appModel.remote.sending().addFileUploadChunk({uploadID: data.uploadID, chunk: toSend}),
							appModel.remote.sending().closeFileUpload({uploadID: data.uploadID})
						]).handle((outcome) -> {
							switch (outcome) {
								case Success(data):
									trace('Success uploading', data);
								case Failure(failure):
									trace('Failure uploading', failure);
							}
						});
					case Failure(failure):
						trace('Failure', failure);
				}
			});
		}
	}

	function close() {
		appModel.writeMailPopupModels = appModel.writeMailPopupModels.filter((popup) -> {
			return popup == this.viewModel ?
				FilterResult.Exclude
				: FilterResult.Include;
		});

		appModel.addToast(new ToastModel({
			text: 'Email sent'
		}));
	}

	function render() {
		return hxx('
            <Popover 
                open=${open} 
                position=${Center} 
                actions={<Button onclick=${handleSend()}>Send</Button>}
                title="Write mail"
                >
                    <TextInput className=${'${WriteMailPopover.__pssClassName}-fields'} value=${to} placeholder="To" /><br/>
                    <TextInput className=${'${WriteMailPopover.__pssClassName}-fields'} value=${subject} placeholder="Subject" /><br/>
                    <TextArea className=${'${WriteMailPopover.__pssClassName}-fields'} value=${message} placeholder="Your email content here" /><br/>
                    <input type="file" ref=${this.fileInput} onchange=${fileChange(null)} />
            </Popover>
        ');
	}
}
