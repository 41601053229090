package senses.api.interfaces;

import tink.core.Promise;

//WARN: Something seems wwrong here...
interface IUsers {
    @:post('/')
    @:consumes('application/json')
    @:restrict(true)
    public function createUser(body:IUser):Promise<ICreateUserAnswer>;
    @:post('/login')
    @:consumes('application/json')
    public function login(body:IUser):Promise<ILoginAnswer>;
}

typedef IUser = {
    name:String,
    password:String
};

typedef ILoginAnswer = {
    success:Bool,
    ?sessionID: String
};

typedef ICreateUserAnswer = {
    success:Bool
};