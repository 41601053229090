package senses.atoms;

import js.html.CSSStyleDeclaration;
import coconut.ui.Children;
import coconut.vdom.View;

@:build(papillotte.macros.PSSBuilder.build('Toolbar.css'))
class Toolbar extends View {
    @:attribute var children:Children;

    function render() {
        return hxx('
            <div className=${Toolbar.__pssClassName}>
                <div>
                    ${...children}
                </div>
            </div>
        ');
    }
}