package senses.mails.atoms;

import coconut.ui.View;

class CustomRaw extends View {
    @:attribute var content:String;

    function render() {
        content;
        return 
            <div ref=${(div) -> {if (div != null) div.innerHTML = content;}}></div>
        ;
    }
}