package senses.summaries;

import coconut.ui.View;
import tink.pure.List;
import senses.models.summaries.Summary;

@:build(papillotte.macros.PSSBuilder.build('SummariesList.css'))
class SummariesList extends View {
    @:attribute var summaries:List<Summary>;

    @:attribute function onMailActivated(mailSummary:Summary):Void {}

    function render() {
        return hxx('
            <ul class=${SummariesList.__pssClassName}>
                <for ${summary in summaries}>
                   <SummaryListEntry summary=${summary} onClick=${onMailActivated(summary)}/>
                </for>
            </ul>
        ');
    }
}