package senses.models.mails;

import tink.core.Error;
import haxe.Json;
import coconut.data.Model;
import tink.pure.Dict;
import tink.pure.List;
import tink.core.Promise;

class MailPart implements Model {
    @:observable var emailID:String;
    @:observable var id:String;
    @:loaded var part:MailPartType = loadPart();
    @:loaded var headers:Dict<String, List<String>> = loadHeaders();
    /**
        This relies on headers
    **/
    // @:loaded var from:String = loadFrom();
    @:loaded var standardHeaders:StandardHeaders = loadStandardHeaders();

    @:loaded var children:List<String> = loadChildren();
    @:loaded var json:String = loadJson();

    function loadStandardHeaders() {
        return new Promise((res, err) -> {
            Promise.inParallel([this.loadFromHeader(), this.loadSubjectHeader(), this.loadCCHeader()]).handle((results) -> {
                trace("LOAD FROM AND SUBJECT", results);
                switch(results) {
                    case Success(results):
                        res(new StandardHeaders({from: results[0], subject: results[1], cc:results[2]}));
                    case Failure(error):
                        err(error);
                }
            });
        });
    }

    private function loadFromHeader() {
        return new Promise((res, err) -> {
            switch (this.headers) {
                case Done(headers):
                     switch (headers.get('from').get(0)) {
                         case None:
                             res(null);
                         case Some(v):
                             res(v);
                     };
                case Loading:
                case Failed(error):
                    err(error);
            } 
         });
    }

    private function loadSubjectHeader() {
        return new Promise((res, err) -> {
            switch (this.headers) {
                case Done(headers):
                     switch (headers.get('subject').get(0)) {
                         case None:
                             res(null);
                         case Some(v):
                             res(v);
                     };
                case Loading:
                case Failed(error):
                    err(error);
            } 
         });
    }

    private function loadCCHeader() {
        return new Promise((res, err) -> {
            switch (this.headers) {
                case Done(headers):
                     switch (headers.get('cc').get(0)) {
                         case None:
                             res(null);
                         case Some(v):
                             res(v);
                     };
                case Loading:
                case Failed(error):
                    err(error);
            } 
         });
    }

    function loadHeaders() {
        return new Promise((success, err) -> {
            switch(json) {
                case Loading:
                case Failed(error):
                    err(error);
                case Done(results):
                    var result = Json.parse(results);
                    var headers:Map<String, Array<String>> = result.headers;
                    var finalHeaders = Dict.empty();
                    for (headerName in Reflect.fields(headers)) {
                        var headerArray = Reflect.field(headers, headerName);
                        var valuesList = List.fromArray(headerArray);
                        finalHeaders = finalHeaders.with(headerName, valuesList);
                    }

                    success(finalHeaders);
            }
        });
    }

    function loadPart():Promise<MailPartType> {
        return new Promise((success, err) -> {
            switch (headers) {
                case Loading:
                case Failed(error):
                    err(error);
                case Done(headersValue):
                    var initialValue = headersValue.get('content-type').get(0);
                    trace(headersValue.get('content-type').get(0));
                    
                    switch (initialValue) {
                        case None:
                            err(new Error("Error while retrieving content type"));
                        case Some(v):
                            var splitted = v.split(';');
                            var ct = splitted[0];

                            switch (ct) {
                                case 'multipart/mixed':
                                    var parts = new Array<MailPart>();
                                    switch (children) {
                                        case Failed(error):
                                            err(error);
                                        case Loading:
                                        case Done(children_):
                                            for (childID in children_) {
                                                parts.push(new MailPart({emailID: emailID, id: childID}));
                                            }
                                            success(MultipartMixed(List.fromArray(parts)));
                                    }
                                case 'multipart/alternative':
                                    var parts = new Array<MailPart>();
                                    switch (children) {
                                        case Failed(error):
                                            err(error);
                                        case Loading:
                                        case Done(children_):
                                            for (childID in children_) {
                                                parts.push(new MailPart({emailID: emailID, id: childID}));
                                            }
                                            success(MultipartAlternative(List.fromArray(parts)));
                                    }
                                case 'multipart/related', 'multipart/report':
                                    trace('Parsing ${this.id} as multipart/report or related');
                                    var parts = new Array<MailPart>();
                                    switch (children) {
                                        case Failed(error):
                                            err(error);
                                        case Loading:
                                        case Done(children_):
                                            for (childID in children_) {
                                                parts.push(new MailPart({emailID: emailID, id: childID}));
                                            }
                                            success(MultipartRelated(List.fromArray(parts), this.id));
                                    }
                                case 'text/plain':
                                    switch (json) {
                                        case Failed(error):
                                            err(error);
                                        case Loading:
                                        case Done(result):
                                            var parsed = Json.parse(result);
                                            success(TextPlain(parsed.text));
                                    }
                                case 'text/html':
                                    switch (json) {
                                        case Failed(error):
                                            err(error);
                                        case Loading:
                                        case Done(result):
                                            var parsed = Json.parse(result);
                                            success(TextHtml(parsed.text));
                                    }
                                default:
                                    success(Unknown(this));
                            }
                    }
            }
        });
    }

    function loadChildren():Promise<List<String>> {
        trace('LOADCHILDREN CALLED');
        return new Promise((success, err) -> {
            switch (json) {
                case Done(v):
                    var parsed = Json.parse(v);
                    trace('CHILDREN LOADING', parsed.children);
                    success(List.fromArray(parsed.children));
                case Loading:
                case Failed(error):
                    err(error);
            }
        });
    }

    function loadJson():Promise<String> {
        return new Promise((success, err) -> {
            Main.remote.parts().getPart(emailID, id).handle((outcome) -> {
                switch(outcome) {
                    case Success(data):
                        success(data.content);
                    case Failure(error):
                        err(error);
                }
            });
        });
    }

}

class StandardHeaders implements Model {
    var from:String;
    var subject:String;
    var cc:String;
}

enum MailPartType {
    MultipartMixed(parts:List<MailPart>, ?id:String);
    MultipartAlternative(parts:List<MailPart>, ?id:String);
    MultipartRelated(parts:List<MailPart>, ?id:String);
    TextPlain(content:String, ?id:String);
    TextHtml(content:String, ?id:String);
    Unknown(part:MailPart, ?id:String);
}