package senses.mails;

import senses.models.AppModel.WriteMailPopupModel;
import senses.atoms.Button;
import js.Browser;
import senses.models.mails.MailPart;
import haxe.Json;
import coconut.ui.View;
import senses.models.summaries.Summary;
import senses.mails.atoms.HeaderView;
import senses.models.AppModel;

@:build(papillotte.macros.PSSBuilder.build('MailView.css'))
class MailView extends View {
    @:attribute var mailModel:Null<MailPart> = null;
    //@:attribute var mailID:String;

    //var mailModel:MailPart;

    function chooseViewRenderer(part:MailPartType) {
        return hxx('<PartTypeView emailID=${mailModel.emailID} partType=${part}/>');
        switch (part) {
            case MultipartAlternative(parts):
                return hxx('
                    <MultipartAlternativeView emailID=${mailModel.emailID} partType=${part}/>
                ');
            default:
                return hxx('<span>Unfortunately we do not currently know how to display this email (${part.getName()})</span>');
        }
    }

    function handleEMLDownload(_) {
        Main.remote.parts().getEMLLink(mailModel.emailID).handle(
            (outcome) -> {
                switch (outcome) {
                    case Success(data):
                        var link = data.content;
                        Browser.window.open('https://api.latearrivalstud.io/parts/getEMLFromLink/${link}');
                    case Failure(failure):
                        throw failure;
                }
            }
        );
    }

    function handleHideButton(_) {
        Main.remote.summaries().setHidden(true, mailModel.id).handle(
            (outcome) -> {
                switch (outcome) {
                    case Success(data):
                        trace('Set ${mailModel.id} HIDDEN field to true');
                        Main.appModel.loadSummaries();
                    case Failure(failure):
                        throw failure;
                }
            }
        );
    }

    function handleReplyButton(replyTo, subject) {
        Main.appModel.writeMailPopupModels = Main.appModel.writeMailPopupModels.append(new WriteMailPopupModel({body: '', to: replyTo, subject: 'Re: ${subject}', from: ''}));
    }

    function replyButton(_:{}) {
        return 
        <switch ${mailModel.standardHeaders}>
            <case ${Loading}>
                <Button>Loading...</Button>
            <case ${Failed(e)}>
                <Button>Error!</Button>
            <case ${Done(v)}>
                <Button onclick=${(_) -> {handleReplyButton(v.from, v.subject);}}>Reply to ${v.from}</Button>
        </switch>;
    }

    function render() {
        return hxx('
            <div class=${MailView.__pssClassName}>
                <div>From: <HeaderView targetHeader="from" headers=${mailModel.headers} targetIndex=${0} /></div>
                <div>To: <HeaderView targetHeader="to" headers=${mailModel.headers} targetIndex=${0} /></div>
                <div>Cc: <HeaderView targetHeader="cc" headers=${mailModel.headers} targetIndex=${0} /></div>
                <div>Subject: <HeaderView targetHeader="subject" headers=${mailModel.headers} targetIndex=${0} /></div>
                <replyButton />
                <Button onclick=${handleEMLDownload}>EML</Button>
                <Button onclick=${handleHideButton}>Hide</Button>
                <hr/>
                <switch ${mailModel.part}>
                    <case ${Done(value)}>
                        <h2>${chooseViewRenderer(value)}</h2>
                    <case ${_}>
                </switch>
            </div>
        ');
    }
}
